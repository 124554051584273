import "../Icons.scss";

const FollowIcon = ({
    className,
    dataIndex,
    color = "#A0ABBB",
    onClick = () => { },
}) => {
    return (
        <div
            className={`icon-wrapper cursor-pointer stroke-color ${className}`}
            data-index={dataIndex}
            onClick={onClick}
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_14391_21457)">
                    <path d="M10.6641 14V12.6667C10.6641 11.9594 10.3831 11.2811 9.88301 10.781C9.38292 10.281 8.70464 10 7.9974 10H3.33073C2.62349 10 1.94521 10.281 1.44511 10.781C0.945014 11.2811 0.664063 11.9594 0.664062 12.6667V14" stroke="#A0ABBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.3359 5.33594V9.33594" stroke="#A0ABBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.3359 7.33594H11.3359" stroke="#A0ABBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z" stroke="#A0ABBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            </svg>

        </div>
    );
};

export default FollowIcon;
