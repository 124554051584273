import "../Icons.scss";

const GalleriesIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.875 13.125L6.17418 8.82582C6.90641 8.09359 8.09359 8.09359 8.82583 8.82583L13.125 13.125M11.875 11.875L13.0492 10.7008C13.7814 9.96859 14.9686 9.96859 15.7008 10.7008L18.125 13.125M3.125 16.25H16.875C17.5654 16.25 18.125 15.6904 18.125 15V5C18.125 4.30964 17.5654 3.75 16.875 3.75H3.125C2.43464 3.75 1.875 4.30964 1.875 5V15C1.875 15.6904 2.43464 16.25 3.125 16.25ZM11.875 6.875H11.8813V6.88125H11.875V6.875ZM12.1875 6.875C12.1875 7.04759 12.0476 7.1875 11.875 7.1875C11.7024 7.1875 11.5625 7.04759 11.5625 6.875C11.5625 6.70241 11.7024 6.5625 11.875 6.5625C12.0476 6.5625 12.1875 6.70241 12.1875 6.875Z"
          stroke="#A0ABBB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default GalleriesIcon;
