import "../Icons.scss";

const PackIcon = ({ className, dataIndex, onClick = () => { } }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.66406 14.6641L9.9974 18.8307L18.3307 14.6641" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.66406 10.5L9.9974 14.6667L18.3307 10.5" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.9974 2.16406L1.66406 6.33073L9.9974 10.4974L18.3307 6.33073L9.9974 2.16406Z" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default PackIcon;
