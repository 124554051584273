export const ROUTES_WORDS = {
    DISPLAY_KEY: "showcase",
    RECOMMENDATION: "newest-collections",
    NEWEST_COLLECTION: "timelines",
    WALLETS: "wallet-authentication",
    ACCOUNT: "account",
    ADD: "add",
    LOYALTY_POINTS: "loyalty-points",
    CHALLENGES:"challenges",
    SUBSCRIPTION: "subscription",
    PREFERENCES: "preferences",
    RESOURCES: "resources",
    NFTS: "nfts",
    TOKEN_BAL: "tokens",
    DASHBOARD: "portfolio",
    DASHBOARD_CREATORS: "creators",
    ADD_FEATURED_PIECES: "featured",
    CREATORS: "creators",
    ACTIVITIES: "activities",
    COLLECTION_TAB: "portfolio",
    GALLERY_TAB: "gallery",
    TIMELINE_TAB: "timeline",
    MEDIA_TAB:"media",
    USER_PROFILE_DASHBOARD: "dashboard",
    UTILITY_KEY:"utility",
    OVERVIEW:"overview",
    LEADERBOARD:"leaderboard",
    REWARD_HISTORY:"reward-history"
}

export const VISUALIZE_BASE_URL=`/${ROUTES_WORDS?.DISPLAY_KEY}`;
export const LEARN_BASE_URL=`/${ROUTES_WORDS?.RESOURCES}`
export const MY_COLLECTIONS=`my-collections`;
export const MY_TOKEN=`my-tokens`;
export const MY_MEDIA=`my-media`;
export const BROWSE_URL=`/curate/collections`;
export const BROWSE_MY_COLLECTIONS=`${BROWSE_URL}/${MY_COLLECTIONS}`;
export const BROWSE_MY_TOKENS=`${BROWSE_URL}/${MY_TOKEN}`;
export const BROWSE_MY_MEDIA=`/media`;
export const LISTEN_MY_COLLECTIONS=`/listen/collections/${MY_COLLECTIONS}`
export const READ_MY_COLLECTIONS=`/read/collections/${MY_COLLECTIONS}`