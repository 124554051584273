import "../Icons.scss";

const StarIcon = ({ className, dataIndex, onClick = () => { } }) => {
    return (
        <div
            className={`icon-wrapper cursor-pointer stroke-color ${className}`}
            data-index={dataIndex}
            onClick={onClick}
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.0026 1.33594L10.0626 5.50927L14.6693 6.1826L11.3359 9.42927L12.1226 14.0159L8.0026 11.8493L3.8826 14.0159L4.66927 9.42927L1.33594 6.1826L5.9426 5.50927L8.0026 1.33594Z" stroke="#A0ABBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    );
};

export default StarIcon;
