import { ROUTES_WORDS, VISUALIZE_BASE_URL } from "App/constants/routes";
import { setCategoryCodeName } from "./utilities";

export function creatorRoutes(route, slug, id, tab,subTab="gallery") {
  const routes = {
    add:  `/creator/${ROUTES_WORDS?.ADD}`,
    idWithTab: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${tab}`,
    overviewWithId: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/overview`,
    subscriptionWithId: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/wallet-authentication`,

    collectionWithCollectionId: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${ROUTES_WORDS?.COLLECTION_TAB}/${id}`,
    collectionWithId: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${ROUTES_WORDS?.COLLECTION_TAB}`,
    collections: `/${ROUTES_WORDS?.ACCOUNT}/creator/${ROUTES_WORDS?.COLLECTION_TAB}`,

    myGalleries: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${subTab}`,
    galleriesWithGalleryId: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${subTab}/view/${id}`,
    galleriesWithAddCollection: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${subTab}/add/collection`,
    galleriesWithSave: `/${ROUTES_WORDS?.ACCOUNT}/creator/${slug}/${subTab}/edit/${id}/save`,

    default: `/${ROUTES_WORDS?.ACCOUNT}/creator`,
  };

  return routes[route] || routes.default;
}
export function discoverCreatorsRoutes(route, categoryName, subCategory) {
  const routes = {
    creatorsLists: `/creators/${categoryName}`,
    creatorsFullLists:`/creators/${categoryName}/${subCategory}`,
    default: `/creators/all`,
  }
  return routes[route] || routes.default;
}

export function discoverCollectionsRoutes(route, categoryName, category, subCategory) {
  const routes = {
    collectionsLists: `/collections/${category}`,
    collectionsFullLists:`/collections/${category}/${subCategory}`,
    collectionsAllLists: `/collections/${category}`,
    collectionsAllFullLists:`/collections/${category}/${subCategory}`,
    default: `/collections`,
  }
  return routes[route] || routes.default;
}
export function discoverGalleriesRoutes(route, categoryName, subCategory) {
  const routes = {
    galleriesLists: `/galleries/${setCategoryCodeName(categoryName)}`,
    galleriesFullLists:`/galleries/${setCategoryCodeName(categoryName)}/${subCategory}`,
    default: `/galleries`,
  }
  return routes[route] || routes.default;
}

export function collectionDetailsRoutes(route) {
  const routes = {
    collectionDetails: `/collections/details`,
    default: `/discover`,
  }
  return routes[route] || routes.default;
}

export function discoverCreatorRoutes(route, contentId, dataId, tab, creatorType) {
  const routes = {
    creatorsDetails: `/@${contentId}/${tab}`,
    creatorsGalleryDetails:`/@${contentId}/gallery/${dataId}`,
    default: `/creators`,
  }
  return routes[route] || routes.default;
}

export function allCreatorRoutes(route, slug, tab,id, action, galleryId) {
  const routes = {
    slugWithTab: `/@${slug}/${tab}`,
    detailsView:`/@${slug}/${tab}/${id}`,
    galleryAddAction: `/@${slug}/${tab}/add/${action}`,
    galleryAddActionId: `/@${slug}/${tab}/add/${action}/${id}`,
    galleryEditAction: `/@${slug}/${tab}/edit/${galleryId}/${action}`,
    galleryEditActionId: `/@${slug}/${tab}/edit/${galleryId}/${action}/${id}`,
    default: `/@${slug}`,
    listing: 'creators/all'
  };

  return routes[route] || routes.default;
}

export function creatorFromOtherRoutes(route, slug, tab,id) {
  const baseUrl = `/portfolio/creators`;
  const routes = {
    slugWithTab: `${baseUrl}/@${slug}/${tab}`,
    detailsView:`${baseUrl}/@${slug}/${tab}/${id}`,
    default: `${baseUrl}`,
  };

  return routes[route] || routes.default;
}
export function allCuratorsRoutes(route, slug, tab, id, action, galleryId, filterKeys="curators") {
  const routes = {
    slugOnly: `/curators/@${slug}`,
    slugWithTab: `/curators/@${slug}/${tab}`,
    slugWithTabWithId: `/curators/@${slug}/${tab}/${id}`,
    galleryAddAction: `/curators/@${slug}/${tab}/add/${action}`,
    galleryAddActionId: `/curators/@${slug}/${tab}/add/${action}/${id}`,
    galleryEditAction: `/curators/@${slug}/${tab}/edit/${galleryId}/${action}`,
    galleryEditActionId: `/curators/@${slug}/${tab}/edit/${galleryId}/${action}/${id}`,
    filterParam:`/${filterKeys}`,
    default: `/curators`,

  };

  return routes[route] || routes.default;
}

export function discoverCollectorRoutes(route, collectorId, tab, id) {
  const routes = {
    collectorDetailsDefault: `/collectors/@${collectorId}`,
    collectorDetails: `/collectors/@${collectorId}/${tab}`,
    collectorGalleryDetails: `/collectors/@${collectorId}/${ROUTES_WORDS?.GALLERY_TAB}/${id}`,
    default: `/collectors`,
  };
  return routes[route] || routes.default;
}

export function dashboardRoutes(route, tab,id) {
  const routes = {
    dashboard: `/${ROUTES_WORDS?.DASHBOARD}`,
    dashboardTabs: `/${ROUTES_WORDS?.DASHBOARD}/${tab}`,
    dashboardTabWithId: `/${ROUTES_WORDS?.DASHBOARD}/${tab}/${id}`,
    default: `/${ROUTES_WORDS?.DASHBOARD}/overview`,
  };

  return routes[route] || routes.default;
}

export function collectorProfileRoutes(route, tab,currentUserId,dataId) {
  const routes = {
    subscription: `/${ROUTES_WORDS?.ACCOUNT}/subscription`,
    profileWithTab: `/${ROUTES_WORDS?.ACCOUNT}/collector/${currentUserId}/${tab}`,
    wallets: `/${ROUTES_WORDS?.ACCOUNT}/${ROUTES_WORDS?.WALLETS}`,
    purchase: `/${ROUTES_WORDS?.ACCOUNT}/purchase`,
    preferences: `/${ROUTES_WORDS?.ACCOUNT}/preferences`,
    loyalty: `/${ROUTES_WORDS?.ACCOUNT}/${ROUTES_WORDS?.LOYALTY_POINTS}`,
    challenges: `/${ROUTES_WORDS?.ACCOUNT}/${ROUTES_WORDS?.CHALLENGES}`,
    collections: `/creator/collection`,
    rewards: `/${ROUTES_WORDS?.ACCOUNT}/rewards`,
    profileViewWithTab: `/${ROUTES_WORDS?.ACCOUNT}/collector/${currentUserId}/${tab}/view`,
    contentDetailsWithTabs: `/${ROUTES_WORDS?.ACCOUNT}/collector/${currentUserId}/${tab}/${dataId}`,
    profileViewWithTabAndSubLevel: `/${ROUTES_WORDS?.ACCOUNT}/collector/${currentUserId}/${tab}/view/${dataId}`,
    profileViewWithTabAndTypeTwo: `/${ROUTES_WORDS?.ACCOUNT}/collector/${currentUserId}/${tab}/${dataId}/view`,
    default: `/${ROUTES_WORDS?.ACCOUNT}/collector/${currentUserId}/overview`,
  };

  return routes[route] || routes.default;
}

export function curatorRoutes(route, id,  tab,childId1) {
  const routes = {
    add:  `/curator/${ROUTES_WORDS?.ADD}`,
    curatorIdWithTab: `/${ROUTES_WORDS?.ACCOUNT}/curator/${id}/${tab}`,
    myGalleries: `/${ROUTES_WORDS?.ACCOUNT}/curator/${id}/gallery`,
    curatorGalleriesWithGalleryId: `/${ROUTES_WORDS?.ACCOUNT}/curator/${id}/gallery/view/${childId1}`,
    galleriesWithAddCollection: `/curator/${id}/gallery/add/collection`,
    galleriesWithSave: `/${ROUTES_WORDS?.ACCOUNT}/curator/${id}/gallery/edit/${childId1}/save`,
    curatorIdWithCollectionId: `/${ROUTES_WORDS?.ACCOUNT}/curator/${id}/collections/${childId1}`,

    discoverCuratorIdWithTab: `/curators/@${id}/${tab}`,
    discoverCuratorId: `/curators/@${id}`,
    discoverCuratorGalleriesWithGalleryId: `/curators/@${id}/gallery/view/${childId1}`,
    discoverCuratorIdWithCollectionId: `/curators/@${id}/collections/${childId1}`,

    default: `/curator`,
  };

  return routes[route] || routes.default;
}
export function browseCollection(route, id, basePath = 'media') {
  const routes = {
    myCollection: `/${basePath}`,
    myCollectionAsset: `/${basePath}/@${id}`,
    default: `/${basePath}`,
  };

  return routes[route] || routes.default;
}
export function userDashboardRoutes(route) {
  const routes = {    
    default: `/${ROUTES_WORDS?.ACCOUNT}/${ROUTES_WORDS?.USER_PROFILE_DASHBOARD}`,
  };

  return routes[route] || routes.default;
}
export function playlistsRoutes(route) {
  const routes = {    
    default: `${VISUALIZE_BASE_URL}/playlists`,
  };

  return routes[route] || routes.default;
}
export function gamingRoutes(route) {
  const routes = {    
    default: `${VISUALIZE_BASE_URL}/gaming`,
};

  return routes[route] || routes.default;
}
export function framesRoutes(route) {
  const routes = {
    default: `${VISUALIZE_BASE_URL}/frames`,
  };
  return routes[route] || routes.default;
}
export function visualizeGallery(route, id, basePath = 'curate') {
  const routes = {    
    galleryView: `${VISUALIZE_BASE_URL}/gallery/view/${id}`,
    editCollectorGallery: `${VISUALIZE_BASE_URL}/gallery/edit/${id}/save`,
    default: `${VISUALIZE_BASE_URL}/gallery`,
  };

  return routes[route] || routes.default;
}
export function visualizeDisplay(route, id, basePath = 'curate') {
  const routes = {    
    displayView: `${VISUALIZE_BASE_URL}/displays/${id}`,
    default: `${VISUALIZE_BASE_URL}/displays`,
  };

  return routes[route] || routes.default;
}
