import "../Icons.scss";

const FrameIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color pr-[1px] ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M3.01153 20C2.79722 19.9997 2.59179 19.9144 2.44025 19.7629C2.28871 19.6113 2.20344 19.4059 2.20312 19.1916V0.8084C2.20344 0.594096 2.28871 0.388661 2.44025 0.237125C2.59179 0.0855898 2.79722 0.000317409 3.01153 0H16.9883C17.2026 0.000317409 17.4081 0.0855898 17.5596 0.237125C17.7111 0.388661 17.7964 0.594096 17.7967 0.8084V19.1916C17.7964 19.4059 17.7111 19.6113 17.5596 19.7629C17.4081 19.9144 17.2026 19.9997 16.9883 20H3.01153ZM16.4719 18.6752V1.3248H3.52792V18.6752H16.4719Z"
          fill="#A0ABBB"
        />
        <path
          d="M5.7683 17.0158C5.61433 17.0156 5.46672 16.9543 5.35785 16.8454C5.24897 16.7366 5.18771 16.589 5.1875 16.435V3.57259C5.18782 3.41868 5.24912 3.27118 5.35799 3.16239C5.46685 3.0536 5.6144 2.9924 5.7683 2.99219H14.2243C14.3782 2.99229 14.5258 3.05346 14.6347 3.16228C14.7436 3.27109 14.8049 3.41865 14.8051 3.57259V16.435C14.8049 16.589 14.7436 16.7366 14.6348 16.8454C14.5259 16.9543 14.3783 17.0156 14.2243 17.0158H5.7683ZM13.4803 15.691V4.31699H6.5123V15.691H13.4803Z"
          fill="#A0ABBB"
        />
      </svg>
    </div>
  );
};

export default FrameIcon;
