import "../Icons.scss";

const FourSquaresIcon = ({ className, dataIndex, onClick = () => { } }) => {
    return (
        <div
            className={`iconWrapper cursor-pointer stroke-color ${className}`}
            data-index={dataIndex}
            onClick={onClick}
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.125 3C1.125 1.96447 1.96447 1.125 3 1.125H4.875C5.91053 1.125 6.75 1.96447 6.75 3V4.875C6.75 5.91053 5.91053 6.75 4.875 6.75H3C1.96447 6.75 1.125 5.91053 1.125 4.875V3Z" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.125 11.125C1.125 10.0895 1.96447 9.25 3 9.25H4.875C5.91053 9.25 6.75 10.0895 6.75 11.125V13C6.75 14.0355 5.91053 14.875 4.875 14.875H3C1.96447 14.875 1.125 14.0355 1.125 13V11.125Z" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.25 3C9.25 1.96447 10.0895 1.125 11.125 1.125H13C14.0355 1.125 14.875 1.96447 14.875 3V4.875C14.875 5.91053 14.0355 6.75 13 6.75H11.125C10.0895 6.75 9.25 5.91053 9.25 4.875V3Z" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.25 11.125C9.25 10.0895 10.0895 9.25 11.125 9.25H13C14.0355 9.25 14.875 10.0895 14.875 11.125V13C14.875 14.0355 14.0355 14.875 13 14.875H11.125C10.0895 14.875 9.25 14.0355 9.25 13V11.125Z" stroke="#A0ABBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    );
};

export default FourSquaresIcon;
